import Cookies from 'js-cookie'
import { parseQueryParams } from '@/plugins/parse-query-params'
import { SravniPartner,
  AdvcakePartner,
  AdmitadPartner,
  GoCpaPartner,
  AdmonPartner
} from '@/plugins/partners'

export const CookieSync = class {
  constructor(domain) {
    this.UTM_AGGREGATED_DATA_COOKIE_NAME = 'utm_aggregated_data'
    this.UTM_AGGREGATED_DATA_COOKIE_DELIMITER = '|||'
    this.UTM_AGGREGATED_DATA_COOKIE_EMPTY_VALUE = '(none)'

    this.domain = domain
    this.currentUtmAggregatedDataCookieValue = Cookies.get(this.UTM_AGGREGATED_DATA_COOKIE_NAME)
    this.queryParams = parseQueryParams(location.search) || {}
    this.isGclidOrYclid = this.queryParams.gclid || this.queryParams.yclid
    this.partnersList = {
      sravni: new SravniPartner({ domain: this.domain }),
      advcake: new AdvcakePartner({ domain: this.domain }),
      admitad: new AdmitadPartner({ domain: this.domain }),
      gocpa: new GoCpaPartner({ domain: this.domain }),
      admon: new AdmonPartner({ domain: this.domain })
    }
  }

  init(sb) {
    // получаем urm_source из queryParams
    this.initUtmSource()

    // Если utm_source нет в queryParams, то мы берем из нашей utm_aggregated_data значение utm_source.
    // Если utm_source = наш партнер(sravni, advcake, etc), то записываем его в текущий стейт utm_source,
    // все остальные значения берем из нашей куки (utm_aggregated_data) или ставим пустые.
    // Иначе все берем из source buster.
    this.setUtmWithCheckPartnerInUrlAndUtmAggregatedData(sb)

    // Устанавливаем текущего партнера, если он указан в utm_source.
    this.setCurrentPartner()

    // Смотрится utm_source в текущем стейте.
    // Если не наш партнер (sravni, advcake, etc) или органический трафик (queryParams.gclid или queryParams.yclid)
    // и нет utm_source sravni, advcake, etc в queryParams, то удаляем куки каждого партнера.
    // Иначе (если sravni, advcake, etc) ставим необходимые куки для каждого партнера на 30 дней (advcake_trackid, advcake_url и т.д),
    this.handlePartner()

    // Собираем куку utm_aggregated_data и ставим ее на 30 дней на домен.
    this.generateUtmAggregatedData()
  }

  initUtmSource() {
    this.utmSource = this.queryParams.utm_source || null
  }

  getUtmAggregatedValue = (utmKey) => {
    if (!this.currentUtmAggregatedDataCookieValue) {
      return null
    }

    const utmItems = this.currentUtmAggregatedDataCookieValue.split(this.UTM_AGGREGATED_DATA_COOKIE_DELIMITER)

    if (utmItems.length === 0) {
      return null
    }

    const utmKeyWithValue = utmItems.find(function (utmItem) {
      return utmItem.includes(utmKey)
    })

    if (!utmKeyWithValue) {
      return null
    }

    return utmKeyWithValue.split('=')[1]
  }

  setUtmWithCheckPartnerInUrlAndUtmAggregatedData(sb) {
    const utmSourceFromAggregatedData = this.getUtmAggregatedValue('utm_source')
    const isOurPartnerUtmSource = !!this.partnersList[utmSourceFromAggregatedData?.toLowerCase()]

    if (!this.utmSource && isOurPartnerUtmSource) {
      this.utmSource = utmSourceFromAggregatedData
      this.utmMedium = this.getUtmAggregatedValue('utm_medium') || this.UTM_AGGREGATED_DATA_COOKIE_EMPTY_VALUE
      this.utmCampaign = this.getUtmAggregatedValue('utm_campaign') || this.UTM_AGGREGATED_DATA_COOKIE_EMPTY_VALUE
      this.utmContent = this.getUtmAggregatedValue('utm_content') || this.UTM_AGGREGATED_DATA_COOKIE_EMPTY_VALUE
      this.utmTerm = this.getUtmAggregatedValue('utm_term') || this.UTM_AGGREGATED_DATA_COOKIE_EMPTY_VALUE
    } else {
      this.utmSource = sb.current.src
      this.utmMedium = sb.current.mdm
      this.utmCampaign = sb.current.cmp
      this.utmContent = sb.current.cnt
      this.utmTerm = sb.current.trm
    }
  }

  setCurrentPartner() {
    this.currentPartner = this.partnersList[this.utmSource?.toLowerCase()] || null
  }

  handlePartner() {
    if (!this.currentPartner && (this.utmSource || this.isGclidOrYclid)) {
      // удаляем куки каждого партнера
      for (const partner in this.partnersList) {
        this.partnersList[partner].removeCookies()
      }
    } else if (this.currentPartner) {
      for (const partner in this.partnersList) {
        const partnerInstance = this.partnersList[partner]
        if (partnerInstance !== this.currentPartner) {
          partnerInstance.removeCookies()
        }
      }

      this.currentPartner.saveCookies()
    }
  }

  generateUtmAggregatedData() {
    const utmAggregatedData =
    `utm_source=${this.utmSource}` +
    this.UTM_AGGREGATED_DATA_COOKIE_DELIMITER +
    `utm_medium=${this.utmMedium}` +
    this.UTM_AGGREGATED_DATA_COOKIE_DELIMITER +
    `utm_campaign=${this.utmCampaign}` +
    this.UTM_AGGREGATED_DATA_COOKIE_DELIMITER +
    `utm_content=${this.utmContent}` +
    this.UTM_AGGREGATED_DATA_COOKIE_DELIMITER +
    `utm_term=${this.utmTerm}`

    Cookies.set(this.UTM_AGGREGATED_DATA_COOKIE_NAME, utmAggregatedData, {
      expires: 30,
      domain: '.' + this.domain,
      path: '/'
    })
  }
}
