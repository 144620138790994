import Cookies from 'js-cookie'
import { parseQueryParams } from '@/plugins/parse-query-params'

export class AdmonPartner {
  constructor({ domain }) {
    this.cpaIdCookieName = 'skb_cpa_transaction_id'
    this.cpaSiteIdCookieName = 'skb_cpa_site_id'
    this.cpaSubCookieName = 'skb_cpa_sub'
    this.cpaUrlCookieName = 'skb_cpa_url'
    const queryParams = parseQueryParams(location.search.toLowerCase()) || {}
    this.cookieDomain = '.' + domain
    this.idFromUrl = queryParams.clickid ?? ''
    this.siteIdFromUrl = queryParams.site_id ?? ''
    this.subIdFromUrl = queryParams.sub ?? ''
    this.idFromCookie = Cookies.get(this.cpaIdCookieName)
    this.siteIdFromCookie = Cookies.get(this.cpaSiteIdCookieName)
    this.subIdFromCookie = Cookies.get(this.cpaSubCookieName)
  }

  saveCookies() {
    if (Boolean(this.idFromUrl || this.idFromCookie) === false) {
      return false
    }

    Cookies.set(this.cpaIdCookieName, this.idFromUrl || this.idFromCookie, {
      expires: 30,
      domain: this.cookieDomain,
      path: '/'
    });

    Cookies.set(this.cpaSiteIdCookieName, this.siteIdFromUrl || this.siteIdFromCookie, {
      expires: 30,
      domain: this.cookieDomain,
      path: '/'
    });

    Cookies.set(this.cpaSubCookieName, this.subIdFromUrl || this.subIdFromCookie, {
      expires: 30,
      domain: this.cookieDomain,
      path: '/'
    });

    Cookies.set(this.cpaUrlCookieName, location.href, {
      expires: 30,
      domain: this.cookieDomain,
      path: '/'
    });
  }

  removeCookies() {
    Cookies.remove(this.cpaIdCookieName, {
      domain: this.cookieDomain
    });

    Cookies.remove(this.cpaUrlCookieName, {
      domain: this.cookieDomain
    });

    Cookies.remove(this.cpaSiteIdCookieName, {
      domain: this.cookieDomain
    });

    Cookies.remove(this.cpaSubCookieName, {
      domain: this.cookieDomain
    });
  }
}
