import Cookies from 'js-cookie'
import { parseQueryParams } from '@/plugins/parse-query-params'

export class AbstractPartner {
  constructor({ domain, idParamName }) {
    this.cpaIdCookieName = 'skb_cpa_transaction_id'
    this.cpaUrlCookieName = 'skb_cpa_url'
    this.cookieDomain = '.' + domain
    const queryParams = parseQueryParams(location.search) || {}
    this.idFromUrl = queryParams[idParamName] ?? ''
    this.idFromCookie = Cookies.get(this.cpaIdCookieName)
  }

  saveCookies() {
    if (Boolean(this.idFromUrl || this.idFromCookie) === false) {
      return false
    }

    // сохраняем id из url или из куки
    Cookies.set(this.cpaIdCookieName, this.idFromUrl || this.idFromCookie, {
      expires: 30,
      domain: this.cookieDomain,
      path: '/'
    })

    Cookies.set(this.cpaUrlCookieName, window.location.href, {
      expires: 30,
      domain: this.cookieDomain,
      path: '/'
    })
  }

  removeCookies() {
    Cookies.remove(this.cpaIdCookieName, {
      domain: this.cookieDomain
    })

    // TODO аналог advcake_url оставляем т.к. есть гипотеза, что он нужен будет для других партнеров
    Cookies.remove(this.cpaUrlCookieName, {
      domain: this.cookieDomain
    })
  }
}
