import Cookies from 'js-cookie'

export class GoCpaPartner {
  constructor({ domain }) {
    this.cpaUrlCookieName = 'skb_cpa_url'
    this.cookieDomain = '.' + domain
  }

  saveCookies() {
    Cookies.set(this.cpaUrlCookieName, location.href, {
      expires: 30,
      domain: this.cookieDomain,
      path: '/'
    });
  }

  removeCookies() {
    Cookies.remove(this.cpaUrlCookieName, {
      domain: this.cookieDomain
    });
  }
}
