import Cookies from 'js-cookie'
import { generateTrackId } from '@/plugins/generate-track-id'

export class AdvcakePartner {
  constructor({ domain }) {
    this.cpaIdCookieName = 'advcake_trackid'
    this.cpaUrlCookieName = 'advcake_url'
    this.cookieDomain = '.' + domain
    this.id = generateTrackId()
  }

  saveCookies() {
    Cookies.set(this.cpaIdCookieName, this.id, {
      expires: 30,
      domain: this.cookieDomain,
      path: '/'
    });

    Cookies.set(this.cpaUrlCookieName, location.href, {
      expires: 30,
      domain: this.cookieDomain,
      path: '/'
    });

    window.advcake_data = window.advcake_data || [];
    window.advcake_data.push({
      pageType: 2,
      currentLead: { name: 'Регистрация' }
    });
  }

  removeCookies() {
    Cookies.remove(this.cpaIdCookieName, {
      domain: this.cookieDomain
    });

    Cookies.remove(this.cpaUrlCookieName, {
      domain: this.cookieDomain
    });
  }
}
